import React from 'react';
import { Trans } from 'react-i18next';

import { Button } from '@core';
import { LinkWrapper, Text } from '@components';
import { Description } from '@containers/servers/servers-cta/components';

import { META_IMAGE, PRICING_LINK } from '@shared/constants/constants';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { GA4_EVENTS } from '@shared/constants/google';
import { getFetchedImage } from '@shared/image-helpers';

// Footer CTA images
import footerImage from '@images/product-page/common-footer-image.png';
import footerImageTablet from '@images/product-page/common-footer-image-tablet.png';
import footerImageTabletTop from '@images/product-page/common-footer-image-tablet-top.png';
import footerImageMobile from '@images/product-page/common-footer-image-mobile.png';
import footerImageMobileTop from '@images/product-page/common-footer-image-mobile-top.png';

const TextLinkWrapper = ({ children, link }) => (
  <LinkWrapper link={link}>
    <Button type="button" variant="text">
      <Text.Body1 as="span" color="inherit">
        {children}
      </Text.Body1>
    </Button>
  </LinkWrapper>
);

export const PAGE_DATA = ({ images }) => ({
  metaData: (t) => ({
    title: t('servers:metaData.title'),
    description: t('servers:metaData.description'),
    image: META_IMAGE,
  }),
  headerCta: ({ locationCount, serverCount }) => ({
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('servers:headerCta.title'),
      description: (
        <Description
          description={t('servers:headerCta.description')}
          locationCount={locationCount}
          serverCount={serverCount}
        />
      ),
    }),
    image: () => ({
      mobile: { src: getFetchedImage(images, 'servers/servers.png') },
      tablet: { src: getFetchedImage(images, 'servers/servers.png') },
      desktop: { src: getFetchedImage(images, 'servers/servers.png') },
    }),
  }),
  streamingSafeSwap: ({ pricingLink = PRICING_LINK, t } = {}) => ({
    data: [
      {
        image: getFetchedImage(images, 'servers/streaming.png'),
        title: t('servers:contentBlocks.speedOptimized.title'),
        description: t('servers:contentBlocks.speedOptimized.description'),
      },
      {
        image: getFetchedImage(images, 'servers/safeswap.png'),
        title: t('servers:contentBlocks.safeSwap.title'),
        description: (
          <Trans
            i18nKey="servers:contentBlocks.safeSwap.description"
            components={[
              <TextLinkWrapper key={0} link="/features/safeswap" />,
              <TextLinkWrapper key={1} link="/features/multihop-plus" />,
            ]}
          />
        ),
        children: (
          <LinkWrapper link="/blog/meet-safeswap-the-latest-innovation-in-the-vpn-industry">
            <Button type="button" variant="text">
              {t('common:links.learnMore')}
            </Button>
          </LinkWrapper>
        ),
        isReversed: true,
      },
    ],
  }),
  requestServer: (t) => ({
    title: t('servers:requestServer.title'),
    description: t('servers:requestServer.description'),
  }),
  faq: ({ freeLocationsCount }) => ({
    title: 'Frequently asked questions',
    items: [
      {
        header: 'Who needs a VPN?',
        listLabel: (
          <React.Fragment>
            All users visiting the web can benefit from a VPN. It is the perfect way to become more
            private and anonymous online. However, a VPN can be helpful in various situations and to
            different people.
            <br />
            <br />
          </React.Fragment>
        ),
        listItems: [
          <React.Fragment key={0}>
            <strong>Public Wi-Fi lovers.</strong> Free Wi-Fi is tempting, but it can cost you more
            than you think. Use a VPN to connect to these hotspots safely.
          </React.Fragment>,
          <React.Fragment key={1}>
            <strong>Travelers.</strong> Headed abroad? A VPN will help you access the internet to
            the fullest, regardless of where you are.
          </React.Fragment>,
          <React.Fragment key={2}>
            <strong>Sports enthusiasts.</strong> Cheer on your team wherever you are. Use a VPN to
            access your favorite sports channels or content online.
          </React.Fragment>,
          <React.Fragment key={3}>
            <strong>Gamers.</strong> Use a VPN for online gaming to defeat regional blocks, reduce
            lag, and mitigate DDoS attacks.
          </React.Fragment>,
          <React.Fragment key={4}>
            <strong>Online shoppers.</strong> A VPN adds some extra protection to your online
            shopping and helps you find the best deals online.
          </React.Fragment>,
          <React.Fragment key={5}>
            <strong>Privacy-conscious.</strong> A VPN is an excellent solution for anyone wishing to
            protect their data, mitigate tracking or beat censorship.
          </React.Fragment>,
        ],
      },
      {
        header: 'What is the function of a VPN server?',
        content:
          'A VPN server relates to a physical or virtual server located in a specific location. As soon as you connect to a VPN server, all of your traffic gets rerouted through it. Thus, it allows you to connect to the internet safely, without compromising your security and privacy. Why? Well, a VPN builds an encrypted tunnel through which your data travels. No one, not even your ISP, can see inside that tunnel. Of course, your IP address also gets encrypted, and you are assigned a new one relating to the location you have chosen.',
      },
      {
        header: 'How to choose the best server?',
        content: (
          <React.Fragment>
            If you are after the best speed and connection possible, we suggest that you pick
            servers closer to your actual location. In this way, data does not have to travel great
            distances and can reach its destinations faster. However, all of our premium servers are
            optimized to offer the best performance possible. Thus, you should not experience any
            significant speed drops. If you do, try to connect to another server.
            <br />
            <br />
            You can also click the “Get Optimal Location” button, and we will connect you to the
            best server for you!
          </React.Fragment>
        ),
      },
      {
        header: 'Why upgrade to premium? ',
        content: `We offer ${freeLocationsCount} free locations that you can connect to without paying a dime. However, our free servers tend to get crowded, which might affect your experience. Our team works hard to handle all of the incoming users. Nevertheless, upgrading to premium will give you access to more servers. With many options to choose from, you will unlikely experience any performance-related problems.`,
      },
      {
        header: 'Will my internet connection slow down after connecting to a VPN?',
        content:
          'We continuously improve our infrastructure to deliver quality services for all our users. Thus, our VPN should not be a burden to your browsing. In some cases, Atlas VPN can even help you reach the true potential of your internet connection. For instance, ISP throttling could make your online experience slow down when you engage in bandwidth-heavy activities. With a VPN, your ISP won’t be able to see what you are doing. As a result, it won’t be able to red flag certain activities and slow them down.',
      },
    ],
  }),
  footerCta: {
    text: ({ pricingLink = PRICING_LINK, t } = {}) => ({
      title: t('servers:footerCta.title'),
      description: t('servers:footerCta.description', { serverCount: SERVER_COUNT }),
    }),
    image: () => ({
      mobile: {
        src: footerImageMobile,
        alt: 'Atlas VPN locations',
      },
      mobileTop: {
        src: footerImageMobileTop,
        alt: 'Atlas VPN locations',
      },
      tablet: {
        src: footerImageTablet,
        alt: 'Atlas VPN locations',
      },
      tabletTop: {
        src: footerImageTabletTop,
        alt: 'Atlas VPN locations',
      },
      desktop: {
        src: footerImage,
        alt: 'Atlas VPN locations',
        position: {
          bottom: 0,
          height: 540,
          maxWidth: 3000,
        },
      },
    }),
  },
});
