import React from 'react';
import { useTranslation } from 'react-i18next';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { graphql } from 'gatsby';

import { Layout, SEO, Text } from '@components';
import {
  CTASection,
  ContentBlockSection,
  FAQSection,
  RequestServerSection,
  ServerListSection,
  ServersCta,
} from '@containers';

import { PAGE_DATA } from '@shared/constants/pages/servers';
import { SERVER_COUNT } from '@shared/constants/server-locations';
import { useServersQuery } from '@data/servers';

const Servers = ({ location, data }) => {
  const { metaData, headerCta, streamingSafeSwap, faq, requestServer, footerCta } = PAGE_DATA({
    images: data.allFile.edges,
  });

  const {
    t,
    i18n: { language },
  } = useTranslation();
  const serverData = useServersQuery();

  return (
    <Layout
      location={location}
      wrapperProps={{ backgroundColor: 'unset' }}
      navProps={{ initialBackgroundColor: 'transparent' }}
    >
      <SEO {...metaData(t)} />

      <ServersCta
        data={headerCta({
          locationCount: serverData?.locationCount,
          serverCount: serverData && SERVER_COUNT,
        })}
      />
      <ServerListSection serverData={serverData} />
      <ContentBlockSection
        sectionProps={{ wrapperProps: { py: { _: 64, md: 88 } } }}
        {...streamingSafeSwap({ t })}
      />
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GOOGLE_RECAPTCHA_REQUEST_SERVER_KEY}
        language={language}
        scriptProps={{ async: true }}
      >
        <RequestServerSection {...requestServer(t)} />
      </GoogleReCaptchaProvider>
      <FAQSection
        {...faq({
          freeLocationsCount: serverData?.groups?.find(({ code }) => code === 'free').servers
            .length,
        })}
      />
      <CTASection
        data={footerCta}
        showAvailablePlatforms
        titleComponent={Text.Heading2}
        sectionProps={{
          wrapperProps: { pb: 0 },
        }}
        wrapperProps={{
          backgroundColor: '#BFE2FF',
        }}
        contentProps={{
          pt: 160,
          pb: 80,
        }}
      />
    </Layout>
  );
};

export default Servers;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language }, ns: { in: ["common", "navigation", "servers"] } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        relativePath: {
          in: ["servers/streaming.png", "servers/safeswap.png", "servers/servers.png"]
        }
        sourceInstanceName: { eq: "images" }
      }
    ) {
      edges {
        ...imageData
      }
    }
  }
`;
